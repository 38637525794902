<template>
  <b-container data-aos="fade-up">
    <img
      class="w-100"
      alt="One of the drivers stood by their car"
      src="../../assets/thaw-drivers.jpg"
    />
    <h1 class="mt-5 mb-4 text-primary">Drivers</h1>
    <p>
      <i>
        Volunteer driving for the TH&amp;W Voluntary Car Scheme is a great way
        for you get involved and help provide a valuable service to your local
        community.
      </i>
    </p>
    <p>
      Volunteer drivers use their own cars and receive a mileage currently 45p a
      mile. The amount of work varies because of demand and availability of
      drivers and it is not possible to guarantee a certain number of trips each
      week. Before becoming a driver you are urged to read and download our
      <b-link to="/good-practice-guide-for-volunteers"
        >Good Practice Guide for Volunteers</b-link
      >.
    </p>
    <h2 class="text-primary">Driver Requirements</h2>
    <ul>
      <li>
        A clean driving licence (3 penalty points may be acceptable at the
        discretion of the Co-ordinator)
      </li>
      <li>A roadworthy car comprehensively insured.</li>
      <li>At least 2 years qualified driving experience.</li>
    </ul>
    <p>
      A history of serious illness must be declared along with any medical
      condition which may affect a volunteer's ability to work for the Scheme.
      If necessary the Co-ordinator may ask the volunteer to provide a letter
      from their GP to confirm their fitness to drive. Any unspent criminal
      convictions must be declared and volunteers must agree to checks
      (disclosures)being undertaken through the DBS.
    </p>
    <p>
      Volunteers must arrange to meet with the Co-ordinator to complete the
      “Volunteer Driver Details” form. They should bring two types of
      identification eg. driving license/passport/utility bill plus a passport
      photo if they wish to become a volunteer.
    </p>
    <p>
      All volunteer drivers will be asked to attend a MIDAS training course
      (classroom based) which will be arranged locally by the Co-ordinator. Once
      all necessary checks have been made the volunteer will be provided with a
      Welcome Pack that includes:
    </p>
    <ul>
      <li>A photo ID card</li>
      <li>A parking permit for use only at designated Lincolnshire Hospitals as required</li>
      <li>Relevant information about the TH&amp;W Voluntary Car Scheme</li>
    </ul>
    <p>
      Drivers must always be aware of the needs and circumstances of their
      passengers and offer appropriate help and support where necessary. The
      Scheme reserves the right to terminate a driver's duties if it considers
      that the responsibilities of the role are not being completed
      satisfactorily.
    </p>
    <h2 class="text-primary">General Requirements</h2>
    <p>Drivers must notify the Co-ordinator of any of the following:</p>
    <ul>
      <li>A change of address / telephone &amp; mobile numbers / email</li>
      <li>A change of vehicle.</li>
      <li>Ill health, particularly if driving is affected.</li>
      <li>Driving offences.</li>
      <li>Criminal offences.</li>
      <li>
        Drivers must ensure that their insurance covers them for voluntary
        driving.
      </li>
      <li>
        After serious illness a driver is required to provide a letter from
        their GP confirming their fitness to return to driving.
      </li>
      <li>
        Drivers are encouraged to stipulate days/times when they may be
        available to drive but a certain amount of flexibility would be
        welcomed.
      </li>
      <li>
        Drivers are under no obligation to undertake trips to any destination
        but once a trip has been accepted drivers should do their best to
        undertake it.
      </li>
      <li>
        Drivers are asked, where possible to take a mobile phone with them on
        trips. This is not essential but could prove helpful and is therefore
        recommended.
      </li>
      <li>
        Drivers are encouraged to keep the Co-ordinator informed of holidays and
        dates when they may not be available to drive.
      </li>
      <li>
        Where possible the Co-ordinator should be made aware of any difficulties
        encountered during a trip which may be relevant to other planned
        journeys.
      </li>
      <li>
        Drivers should always display their car signs in hospital car parks as required and
        follow arrangements for parking of volunteer drivers. These car signs
        are not valid at any other time. If payment is required for car parking
        this should be met by the passenger.
      </li>
      <li>
        Drivers should alert the Co-ordinator if they think that a passenger's
        health or mobility gives grounds for concern.
      </li>
      <li>
        Drivers may be asked to take a folding frame/crutches or a folding
        wheelchair in their vehicle but are under no obligation to do so.
      </li>
    </ul>
  </b-container>
</template>

<script>
export default {
  name: "Drivers",
};
</script>
